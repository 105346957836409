/* global $ */

import '../components/slick';
import "../../css/app/pages/home.scss";

class Home
{
    constructor () 
    {
        $('.slick-carousel').slick();
        console.log('Home initialised');
    }
}

new Home;